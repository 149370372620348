<script>
import Layout from "@/router/website-template/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Informasi Component
 */
export default {
    page: {
        title: "Informasi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
    },
    data() {
        return {
            title: "Kontak",
            id: "99caddff-674f-4ce4-9fb0-2ce4a43f0973",
            // variable Page
            nama_halaman: null,
            banner_path: null,
            banner_full: null,
            konten: null,
            gambar_halaman_path: null,
            kategori: null,
            meta_content: {
                map_url: "",
                nama_perseroan: "",
                sub_judul: "",
                head_office: [],
                breeding_facility: [],
            },
        };
    },
    mounted() {
        if (localStorage.getItem('reloaded')) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem('reloaded');
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        let self = this;
        // get data halaman berdasarkan id
        var config = {
            method: "get",
            url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-website/" + self.id,
            params: {},
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
            },
        };
        axios(config)
            .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    var data_edit = response_data_fix;
                    self.nama_halaman = data_edit.nama_halaman;
                    self.banner_path = data_edit.banner;
                    self.banner_full = process.env.VUE_APP_BACKEND_URL + data_edit.banner;
                    self.konten = data_edit.konten;
                    self.gambar_halaman_path = data_edit.gambar_halaman;
                    self.gambar_halaman_full = process.env.VUE_APP_BACKEND_URL + data_edit.gambar_halaman;
                    self.kategori = data_edit.kategori?.nama_kategori_halaman;
                    self.meta_content = JSON.parse(data_edit.meta_content);
                    Swal.close();
                } else {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                Swal.close();
            });
    },
};
</script>

<template>
    <Layout>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <iframe :src="meta_content.map_url" height="450" style="border: 0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade" class="w-100"></iframe>
                </div>
            </div>
            <!-- end col -->
        </div>
        <div class="bg-grey-cust">
            <div class="container mt-3 py-1">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row mt-3">
                                            <div class="col-md-6">
                                                <img :src="banner_full" style="width: 100px; padding-right: 10px;" />
                                                <b class="text-dark" style="font-size: large;"> {{
                                                    meta_content.nama_perseroan }}</b><br />
                                                <br />
                                                <b class="text-dark" style="font-size: medium;">The Leading Vaccine
                                                    Manufacturer in Indonesia</b>
                                                
                                                <div v-html="konten" class="text-dark mt-3"></div>
                                            
                                            </div>
                                            <div class="col-md-6">
                                                <h3 class="text-dark">Head Office:</h3>
                                                <section class="mb-4">
                                                    <ul class="list-inline">
                                                        <li v-for="(item, index) in meta_content.head_office" :key="index">
                                                            <a class="text-dark" :href="item.judul"> <i
                                                                    :class="item.icon"></i> {{ item.judul }}</a>
                                                        </li>
                                                    </ul>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end col -->
            </div>
        </div>

    <!-- end row -->
    </Layout>
</template>
